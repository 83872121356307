import $ from 'jquery';
import 'slick-carousel';
import 'jquery-colorbox';

$('.slider_main').slick({
    autoplay: true,
    autoplaySpeed: 5000,
    asNavFor: '.slider_side',
    infinite: false,
    responsive: [
        {
            breakpoint: 769,
            settings: {
                infinite: true,
            }
        }
    ]
});

$('.slider_side').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    vertical: true,
    infinite: false,
    asNavFor: '.slider_main',
    arrows: true,
    focusOnSelect: true,
    responsive: [
        {
            breakpoint: 769,
            settings: {
                slidesToShow: 1,
                vertical: false,
                centerMode: true,
                infinite: true,
                centerPadding: '25%',
                variableWidth: true
            }
        }
    ]
});



//slideMenu

function slideMenu() {
    const menu_switch = document.getElementById('menu_switch');
    const slideMenu = document.getElementById('slideMenu');

    menu_switch.addEventListener('click', function () {
        menu_switch.classList.toggle('open');
        slideMenu.classList.toggle('open');
    })
}

function slideMenu_acd() {
    const acdSwitch = document.getElementsByClassName('slideMenu_item');

    for (let i = 0, len = acdSwitch.length; i < len; i++) {
        acdSwitch[i].addEventListener('click', function (e) {
            let tgtId = e.target.dataset.tgtacd;

            e.target.classList.toggle('open');

            let tgtAcd = document.getElementById(tgtId);

            if (tgtAcd.style.height) {
                tgtAcd.style.height = null;
            } else {
                tgtAcd.style.height = tgtAcd.scrollHeight + 'px';
            }

            return false;
        })
    }
}

slideMenu();
slideMenu_acd();